<template>
  <div class="setting-bank-th">
    <div class="box-setting-header">
      <div class="d-flex">
        <div class="title mr-4 ml-3 mt-2">Gestion des banques</div>
        <div v-if="getBankLoading" class="loading mr-4 mt-2">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button variant="success" class="config-btn ml-2" v-b-modal.addBank>
          <font-awesome-icon icon="plus" /> Ajouter une banque
        </b-button>
      </div>
    </div>
    <div class="ligne-box-setting-header mt-2"></div>
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-end-position">
          <search
            :value="search"
            @changeSearchValue="changeSearchValue"
            classToUse="search-style"
          />
          <b-button
            size="sm"
            class="button-export-style mr-1 ml-1"
            @click="handelClickExport()"
            title="Télecharger xls"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
      </div>
    </div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-bank-params"
          :items="getBankRows"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneEnAttend"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              class="button-modifier-style ml-1"
              size="sm"
              variant="success"
              @click.prevent.stop="handleClickUpdateBank(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
          <template v-slot:cell(name)="data">
            {{ data.item.name }}
          </template>
        </b-table>
        <!-- <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getBankTotalRows"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div> -->
      </div>
    </div>
    <b-modal
      ref="addBank"
      id="addBank"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap modal-dialog-bien"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Ajouter une banque</div>
        <div class="iconClose" @click.prevent="hideModal('addBank')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            v-if="bankToAdd != null"
            class="form-modal-custom-style visibilty-bien"
            @submit.prevent.stop="submitAddBank"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                id="nom"
                v-model="bankToAdd.name"
                required
              ></b-form-input>
            </b-form-group>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loandingAddBank"
              >
                <span>
                  Valider
                  <div v-if="loandingAddBank" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <div class="messageError">
                <div v-if="getBankError" class="error">
                  <ul
                    v-if="Array.isArray(getBankError)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li v-for="(e, index) in getBankError" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ getBankError }}</div>
                </div>
              </div>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="updateBank"
      id="updateBank"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap modal-dialog-bien"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier une banque</div>
        <div class="iconClose" @click.prevent="hideModal('updateBank')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            v-if="bankToUpdate != null"
            class="form-modal-custom-style visibilty-bien"
            @submit.prevent.stop="submitUpdateBank"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                id="nom"
                v-model="bankToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loandingUpdateBank"
              >
                <span>
                  Valider
                  <div v-if="loandingUpdateBank" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <div class="messageError">
                <div v-if="getBankError" class="error">
                  <ul
                    v-if="Array.isArray(getBankError)"
                    class="mb-0"
                    style="list-style-type: none"
                  >
                    <li v-for="(e, index) in getBankError" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ getBankError }}</div>
                </div>
              </div>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      page: 1,
      perPage: 10,
      search: null,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          key: 'name',
          label: 'Nom',
          thStyle: { width: '100% !important' },
          tdStyle: { width: '100% !important' }
        },
        {
          key: 'Actions',
          label: 'Actions',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation'
        }
      ],
      bankToAdd: {
        name: null
      },
      bankToUpdate: null,
      searchValue: null,
      loandingAddBank: false,
      loandingUpdateBank: false,
      bankToUpdateOrigin: null
    };
  },
  computed: {
    ...mapGetters([
      'getBankTotalRows',
      'getBankError',
      'getBankLoading',
      'getBankRows'
    ]),
    rowDetailsClass(index) {
      if (index % 2 == 0) {
        return 'tdvalidation';
      } else {
        return 'ligneEnAttend';
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllBanks',
      'createBnak',
      'updateBank',
      'resetVarBank',
      'exporXlsBanque'
    ]),
    resetModal() {
      this.resetVarBank();
      this.bankToAdd = {
        name: null
      };
      this.bankToUpdate = null;
      this.bankToUpdateOrigin = null;
    },
    changeSearchValue(event) {
      this.search = event;
      this.getAllBanks({
        search: this.search
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async submitAddBank() {
      this.loandingAddBank = true;
      const response = await this.createBnak(this.bankToAdd);
      if (response) {
        this.hideModal('addBank');
      }
      this.loandingAddBank = false;
    },
    handleClickUpdateBank(bank) {
      this.bankToUpdate = { ...bank };
      this.bankToUpdateOrigin = bank;
      this.$refs['updateBank'].show();
    },
    async submitUpdateBank() {
      this.loandingUpdateBank = true;
      const response = await this.updateBank({
        bank: this.bankToUpdate,
        bankOrigin: this.bankToUpdateOrigin
      });
      if (response) {
        this.hideModal('updateBank');
      }
      this.loandingUpdateBank = false;
    },
    handelClickExport() {
      this.exporXlsBanque();
    }
  },
  components: {
    Card: () => import('../component/card.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  mounted() {
    this.getAllBanks();
  }
};
</script>

<style scoped lang="scss">
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.setting-bank-th {
  .table-bank-params {
    max-height: calc(100vh - 179px) !important;
    height: calc(100vh - 179px) !important;
    margin-bottom: 0px;
    .button-danger-style {
      background-color: #dc3c3c;
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 15px;
      border: none;
    }
    .button-warning-style {
      background-color: #ffc107;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #ffffff;
      border: none;
    }
    .button-duplicate-style {
      background-color: #17a2b8;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #ffffff;
      border: none;
    }
    .button-modifier-style {
      background-color: #699d86;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #ffffff;
      border: none;
    }
  }

  .body-box-setting {
    display: flex;
    background-color: #f5f4fa;
    height: auto;
    margin-top: 3px;
    border-radius: 28px;
    position: relative;
    padding: 11px;
  }
  .table-biens-params {
    .thvalidation,
    .tdvalidation {
      min-width: 107px !important;
    }
  }
  .bien-block {
    color: #000;
    position: relative;
    overflow: visible;
    font-size: 12px;
    margin-top: 5px;
    border: 1px solid rgba(108, 117, 125, 0.3215686275);
    padding: 4px;
    background-color: #f5f4fa;
    width: 90%;
    border-radius: 10px;
    margin: 0px auto;
  }
  .table-biens-params {
    .color-row-bien {
      min-width: 42px;
    }
    .row-action-bien {
      min-width: 107px !important;
    }
    .item-details-style {
      border: none;
    }
  }
}
</style>
